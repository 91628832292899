import { Component, Input, Inject, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core"
import { FormControl } from "@angular/forms"
import { take } from "rxjs/operators"
import { max, compareDesc } from "date-fns"

import { Destruct, StaticSource, Model, Field, LoadFields, ToastService } from "@anzar/core"
import { Client, CaseTransfer, CaseBackend, ClientBackend } from "@backend/client.api"
import { Event, EventBackend } from "@backend/event.api"
import { Section } from "@backend/org.api"
import { User } from "@backend/pyzar.api"
import { KenysziBackend } from "@backend/kenyszi.api"
import { BedReservationBackend, BedReservation } from "@backend/service_room.api"
import { MailingBackend, Mailing } from "@backend/service_mailing.api"
import { ResourceReservationBackend, ResourceReservation } from "@backend/service_schedule.api"
import { BNOReservationBackend, BNOReservation } from "@backend/service_bno.api"


const CASE_FIELDS: LoadFields<CaseTransfer> = [
    "type", "state", "accepted_time",
    { from_user: ["id", "name"] },
    { to_user: ["id", "name"] },
]


class EventRange extends Model {
    @Field({ primary: true }) public id: string
    @Field() public label: string
}


interface EventSummaryEntry {
    lastDate: Date
    count: number
    workers: Array<{ worker: User, count: number }>
}


interface EventSummary {
    section: Section
    lastDate: Date
    care: EventSummaryEntry
    usage: EventSummaryEntry
}


@Component({
    selector: ".rege-client-activity",
    templateUrl: "./client-activity.component.pug",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientActivityComponent implements OnInit, OnDestroy {
    public readonly destruct = new Destruct()

    @Input() public client: Client

    public firstEvent: Event
    public activeBedReservations: Array<[Section, BedReservation]> = []
    public activeBnoReservations: Array<[Section, BNOReservation]> = []
    public activeMailings: Array<[Section, Mailing]> = []
    public activeSchedule: Array<[Section, ResourceReservation]> = []
    public eventSummaries: Array<EventSummary> = []
    public prevKenysziIds: Array<number> = []
    public caseHistory: CaseTransfer[] = []

    public readonly eventsRange = new FormControl()
    public readonly eventsRangeSrc = new StaticSource(EventRange, [
        { id: "P7D", label: "Hétben" },
        { id: "P1M", label: "Hónapban" },
        { id: "P1Y", label: "Évben" },
    ])

    public changeKenysziId: boolean = false
    public readonly newKenysziId = new FormControl()

    public constructor(
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(EventBackend) private readonly eventBackend: EventBackend,
        @Inject(BedReservationBackend) private readonly bedResv: BedReservationBackend,
        @Inject(MailingBackend) private readonly mailing: MailingBackend,
        @Inject(ResourceReservationBackend) private readonly resourceResv: ResourceReservationBackend,
        @Inject(BNOReservationBackend) private readonly bnoResv: BNOReservationBackend,
        @Inject(KenysziBackend) private readonly kenysziBackend: KenysziBackend,
        @Inject(CaseBackend) private readonly caseBackend: CaseBackend,
        @Inject(ClientBackend) private readonly clientBackend: ClientBackend,
        @Inject(ToastService) private readonly toast: ToastService) {

        this.destruct.subscription(this.eventsRange.valueChanges).subscribe(value => {
            this._updateEventSummary()
        })
    }

    public ngOnInit() {
        this.eventsRange.setValue("P7D")
        this._updateFirstEvent()
        this._updateActiveBedResv()
        this._updateActiveMailing()
        this._updateActiveScheduledServices()
        this._updateActiveBnos()
        this._updatePrevKenysziId()
        this._loadCaseHistory()
    }

    public ngOnDestroy() {
        this.destruct.run()
    }

    private _updateFirstEvent() {
        this.eventBackend
            .search({
                filter: {
                    client_id: this.client.id
                },
                order: { subject_time: "asc" },
                begin: 0,
                count: 1
            })
            .pipe(take(1))
            .subscribe(events => {
                this.firstEvent = events[0]
            })
    }

    private _updateActiveBedResv() {
        this.bedResv
            .query_active({ client_id: this.client.id, subject_date: new Date() })
            .pipe(take(1))
            .subscribe(result => {
                this.activeBedReservations = result
            })
    }

    private _updateActiveBnos() {
        this.bnoResv
            .query_active({ client_id: this.client.id, subject_date: new Date() })
            .pipe(take(1))
            .subscribe(result => {
                this.activeBnoReservations = result
            })
    }

    private _updateActiveMailing() {
        this.mailing
            .query_active({ client_id: this.client.id, subject_date: new Date() })
            .pipe(take(1))
            .subscribe(result => {
                this.activeMailings = result
            })
    }

    private _updateActiveScheduledServices() {
        this.resourceResv
            .query_active({ client_id: this.client.id, subject_date: new Date() })
            .pipe(take(1))
            .subscribe(result => {
                this.activeSchedule = result
            })
    }

    private _updateEventSummary() {
        this.eventBackend
            .query_summary({ client_id: this.client.id, duration: this.eventsRange.value })
            .pipe(take(1))
            .subscribe(result => {
                let bySection: { [key: number]: EventSummary } = {}
                let eventGroup: "usage" | "care"
                let workerSum: { [key: string]: { worker: User, section: Section, count: number, type: typeof eventGroup } } = {}


                for (const [section, worker, event_type, count, last_date] of result) {
                    if (!bySection[section.id]) {
                        bySection[section.id] = {
                            section,
                            lastDate: last_date,
                            care: { count: 0, lastDate: last_date, workers: [] },
                            usage: { count: 0, lastDate: last_date, workers: [] },
                        }
                    }

                    if (event_type === "service") {
                        eventGroup = "usage"
                    } else {
                        eventGroup = "care"
                    }

                    bySection[section.id][eventGroup].count += count
                    bySection[section.id][eventGroup].lastDate = max([bySection[section.id][eventGroup].lastDate, last_date])
                    bySection[section.id].lastDate = max([bySection[section.id].lastDate, last_date])

                    const workerUid = `${section.id}-${worker.id}-${eventGroup}`
                    if (!workerSum[workerUid]) {
                        workerSum[workerUid] = { section, worker, count, type: eventGroup }
                    } else {
                        workerSum[workerUid].count += count
                    }
                }

                Object.keys(workerSum)
                    .map(k => workerSum[k])
                    .sort((a, b) => a.count - b.count)
                    .forEach(value => {
                        bySection[value.section.id][value.type].workers.push({ worker: value.worker, count: value.count })
                    })

                this.eventSummaries = Object.keys(bySection)
                    .map(k => bySection[k as any])
                    .sort((a, b) => compareDesc(a.lastDate, b.lastDate))
                this.cdr.markForCheck()
            })
    }

    private _updatePrevKenysziId() {
        this.kenysziBackend
            .get_merged_kenyszi_ids({ client_id: this.client.id })
            .pipe(take(1))
            .subscribe(result => {
                this.prevKenysziIds = result
                this.cdr.markForCheck()
            })
    }

    private _loadCaseHistory() {
        this.caseBackend
            .search({
                filter: { state: "accepted", client_id: this.client.id },
                order: { accepted_time: "asc" }
            }, { loadFields: CASE_FIELDS })
            .subscribe(result => {
                this.caseHistory = result
            })
    }

    public startChangeKenysziId() {
        this.newKenysziId.setValue(null)
        this.changeKenysziId = true
    }

    public cancelChangeKenysziId() {
        this.changeKenysziId = false
    }

    public doChangeKenysziId() {
        const idTevadminIgenybevevo = this.newKenysziId.value
        this.clientBackend
            .change_idTevadminIgenybevevo({ client_id: this.client.id, idTevadminIgenybevevo })
            .pipe(this.toast.handleSave({ align: "bottom center", successMsg: "Kenyszi ID sikeresen módosítva" }))
            .subscribe(v => {
                this.client.idTevadminIgenybevevo = idTevadminIgenybevevo
                this.changeKenysziId = false
                this._updatePrevKenysziId()
            })
    }
}
