import { Component, Inject, ChangeDetectorRef, ChangeDetectionStrategy, OnInit, InjectionToken, Optional } from "@angular/core"
import { FormControl } from "@angular/forms"
import { forkJoin, of } from "rxjs"
import { take, switchMap, tap, shareReplay } from "rxjs/operators"

import { Destruct, LayerRef, ToastService, ClosingGuarded, LoadFields, DragService, FullscreenLayer } from "@anzar/core"
import { ClientBackend, Client, CaseBackend, CaseTransfer } from "@backend/client.api"
import { User } from "@backend/pyzar.api"
import { WorkerBackendSource } from "@backend/worker.api"
import { ClosingGuardService } from "../common"
import { CurrentSection } from "../section.service/level"
import { MarkService } from "../mark.module"
import { AuthService } from "@pyzar/auth.module"
import { FsService } from "@pyzar/fs.module"
import { CLIENT_FIELDS } from "./client.service"
import { WorkerCardService } from "../worker.module"



export const INITIAL_TAB_INDEX = new InjectionToken("INITIAL_TAB_INDEX")
export const FULLSCREEN = new InjectionToken("FULLSCREEN")



const CASE_TRANSFER_FIELDS: LoadFields<CaseTransfer> = [
    "id", "type",
    { from_user: ["id", "name"] },
    { to_user: ["id", "name"] }
]


@Component({
    selector: "rege-client-sheet",
    templateUrl: "./client-sheet.template.pug",
    host: {
        "[style.width]": "isFs ? null : '960px'"
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [CurrentSection, MarkService, ClosingGuardService, DragService]
})
export class ClientSheet implements OnInit, ClosingGuarded {
    public readonly destruct = new Destruct()

    public readonly userFields: LoadFields<User> = ["id", "name"]

    public set inHandover(val: boolean) {
        if (this._inHandover !== val) {
            this.newManagerId.reset()
            this._inHandover = val
            this.cdr.markForCheck()
        }
    }
    public get inHandover(): boolean { return this._inHandover }
    private _inHandover: boolean

    public readonly newManagerId = new FormControl()
    public currentCaseTransfer: CaseTransfer
    public sectionId: number

    public set inAnimation(val: boolean) {
        if (this._inAnimation !== val) {
            this._inAnimation = val
            this.cdr.markForCheck()
        }
    }
    public get inAnimation(): boolean { return this._inAnimation }
    public _inAnimation: boolean = true

    public set selectedTabIndex(val: number) {
        if (this._selectedTabIndex !== val) {
            this._selectedTabIndex = val
            this.cdr.markForCheck()
        }
    }
    public get selectedTabIndex(): number { return this._selectedTabIndex }
    public _selectedTabIndex: number

    public profileImage: string

    public constructor(
        @Inject(ChangeDetectorRef) protected readonly cdr: ChangeDetectorRef,
        @Inject(LayerRef) protected readonly layerRef: LayerRef,
        @Inject(CurrentSection) protected readonly section: CurrentSection,
        @Inject(MarkService) protected readonly markService: MarkService,
        @Inject(CaseBackend) private readonly caseBackend: CaseBackend,
        @Inject(ToastService) private readonly toastService: ToastService,
        @Inject(ClientBackend) public readonly clientBackend: ClientBackend,
        @Inject(WorkerBackendSource) public readonly userSrc: WorkerBackendSource,
        @Inject(ClosingGuardService) private readonly closingSvc: ClosingGuardService,
        @Inject(AuthService) public readonly authService: AuthService,
        @Inject(INITIAL_TAB_INDEX) @Optional() initialTabIndex: number,
        @Inject(FULLSCREEN) public readonly isFs: boolean,
        @Inject(Client) public client: Client,
        @Inject(WorkerCardService) private readonly workerCardSvc: WorkerCardService,
        @Inject(FsService) private readonly fsService: FsService,
        @Inject(DragService) dragSvc: DragService) {
        dragSvc.draggable = layerRef.behavior instanceof FullscreenLayer ? null : layerRef.container

        this._selectedTabIndex = initialTabIndex || 0

        layerRef.subscribe(event => {
            if (event.type === "shown") {
                this.inAnimation = false
            } else if (event.type === "showing" || event.type === "hiding") {
                this.inAnimation = true
            }
        })

        if (client.profile_image_id) {
            this.profileImage = fsService.getImageUrl(client.profile_image_id, 100, 100)
        } else {
            this.profileImage = null
        }

        layerRef.destruct.subscription(dragSvc.dragging).subscribe(event => {
            if (event.type === "begin") {
                layerRef.behavior.levitate.suspend()
            }
        })
    }

    public ngOnInit() {
        const q = [
            this.section.id$
                .pipe(
                    take(1),
                    tap(sectionId => {
                        this.sectionId = sectionId
                    })
                ),

            this.updateCaseTransfer()
        ]

        forkJoin(...q).subscribe(_ => {
            this.cdr.markForCheck()
        })
    }

    public caseTakeOver() {
        this.caseBackend.take_over({ client_id: this.client.id })
            .pipe(
                take(1),
                this.toastService.handleSave({
                    align: "bottom center",
                    successMsg: this.client.manager_id ? "Esetátkérés kezdeményezése sikeres" : "Az esetet sikeres átvetted"
                }),
                switchMap(v => this.reload())
            )
            .subscribe()
    }

    public caseHandOverBegin() {
        this.inHandover = true
    }

    public caseHandOverCancel() {
        this.inHandover = false
    }

    public caseHandOver() {
        this.caseBackend.hand_over({ client_id: this.client.id, user_id: this.newManagerId.value })
            .pipe(
                take(1),
                this.toastService.handleSave({ align: "bottom center", successMsg: "Esetátadás kezdeményezése sikeres" }),
                switchMap(v => this.reload())
            )
            .subscribe(_ => {
                this.inHandover = false
            })
    }

    public caseDrop() {
        this.caseBackend.drop({ client_id: this.client.id })
            .pipe(
                take(1),
                this.toastService.handleSave({ align: "bottom center", successMsg: "Esetleadás kezdeményezése sikeres" }),
                switchMap(v => this.reload())
            )
            .subscribe()
    }

    public applyCurrentTransfer() {
        this._setCTDecision(true)
    }

    public rejectCurrentTransfer() {
        this._setCTDecision(false)
    }

    private _setCTDecision(accept: boolean) {
        const ct = this.currentCaseTransfer
        if (ct) {
            this.currentCaseTransfer = null
            this.caseBackend
                .set_decision({ case_transfer_id: ct.id, accept: accept })
                .pipe(
                    take(1),
                    this.toastService.handleSave({
                        align: "bottom center",
                        successMsg: accept
                            ? "Esetátadás elfogadva"
                            : "Esetátadás elutasítva"
                    }),
                )
                .subscribe(_ => {
                    this.reload().subscribe()
                })
        }
    }

    public close() {
        this.layerRef.close()
    }

    public canClose(layerRef: LayerRef) {
        return this.closingSvc.confirm()
    }

    public showWorkerCard(userId: number) {
        this.workerCardSvc.show(userId)
    }

    public reload() {
        return this.clientBackend.get({ id: this.client.id }, { loadFields: CLIENT_FIELDS })
            .pipe(tap(client => {
                this.client = client
                if (client.profile_image_id) {
                    this.profileImage = this.fsService.getImageUrl(client.profile_image_id, 100, 100)
                } else {
                    this.profileImage = null
                }
                this.updateCaseTransfer().subscribe(this.cdr.markForCheck.bind(this.cdr))
            }))
    }

    private updateCaseTransfer() {
        return this.caseBackend.status({ client_id: this.client.id }, { loadFields: CASE_TRANSFER_FIELDS })
            .pipe(
                take(1),
                tap(v => {
                    this.currentCaseTransfer = v
                })
            )
    }
}
