import { Injectable, Inject, InjectionToken } from "@angular/core"
import { ComponentType } from "@angular/cdk/portal"
import { BehaviorSubject } from "rxjs"

import type { HelpPage } from "./base"


export interface HelpPageMeta {
    title: string
    path: string[]
    component: ComponentType<HelpPage>
    extra: { [key: string]: any }
}


export interface MenuItem {
    id: number,
    title: string
    children: Array<MenuItem>
    readonly active?: boolean
    component?: ComponentType<HelpPage>,
    parent: MenuItem
}


export const HELP_PAGE_META = new InjectionToken<HelpPageMeta>("HELP_PAGE_META")


@Injectable()
export class HelpPageService {
    public readonly menu: MenuItem

    public readonly activeMenu$: BehaviorSubject<MenuItem>

    public constructor(@Inject(HELP_PAGE_META) private readonly pages: HelpPageMeta[]) {
        this.menu = this._createMenuTree()
        const active = this._findFirstItemWithComponent(this.menu)
        this._clearActive(this.menu)
        active && this._setActive(active)
        this.activeMenu$ = new BehaviorSubject<MenuItem>(active)
    }

    public setActiveMenu(item: MenuItem) {
        item = this._findFirstItemWithComponent(item)
        this._clearActive(this.menu)
        this._setActive(item)
        this.activeMenu$.next(item)
    }

    private _setActive(item: MenuItem) {
        (item as { active: boolean }).active = true
        if (item.parent) {
            for (const sibling of item.parent.children) {
                (sibling as { active: boolean }).active = sibling === item
            }
            this._setActive(item.parent)
        }
    }

    private _clearActive(item: MenuItem) {
        (item as { active: boolean }).active = false
        for (const child of item.children) {
            this._clearActive(child)
        }
    }

    protected _createMenuTree(): MenuItem {
        let result: MenuItem = { id: this._nextItemId(), title: null, children: [], active: true, parent: null }
        for (const page of this.pages) {
            let menuItem = this._insertMenuItem(result, page.path)
            menuItem.children.push({ id: this._nextItemId(), title: page.title, children: [], component: page.component, parent: menuItem })
        }
        return result
    }

    protected _insertMenuItem(menu: MenuItem, path: string[]): MenuItem {
        let result = menu

        for (let i = 0, l = path.length; i < l; i++) {
            const p = path[i]
            let found = false

            for (const entry of result.children) {
                if (entry.title === p) {
                    result = entry
                    found = true
                    break
                }
            }

            if (!found) {
                let newItem: MenuItem = { id: this._nextItemId(), title: p, children: [], parent: result }
                result.children.push(newItem)
                result = newItem
            }
        }

        return result
    }

    private _idCounter = 0
    protected _nextItemId(): number {
        return this._idCounter++
    }

    private _findFirstItemWithComponent(menu: MenuItem): MenuItem {
        if (menu.component) {
            return menu
        } else {
            for (const child of menu.children) {
                const found = this._findFirstItemWithComponent(child)
                if (found) {
                    return found
                }
            }
        }
    }
}
