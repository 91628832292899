import { Component, Inject, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from "@angular/core"
import { ComponentPortal } from "@angular/cdk/portal"

import { Destructible } from "@anzar/core"
import { HelpPageService } from "./help-page.service"
import { HelpPage } from "./base"


@Component({
    selector: ".rege-help",
    templateUrl: "./help.component.pug",
    providers: [HelpPageService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpComponent extends Destructible implements OnInit {
    public helpPagePortal: ComponentPortal<HelpPage>

    public constructor(
        @Inject(HelpPageService) public readonly helpPage: HelpPageService,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef) {
        super()
    }

    public ngOnInit() {
        this.destruct.subscription(this.helpPage.activeMenu$).subscribe(menuItem => {
            if (menuItem && menuItem.component) {
                this.helpPagePortal = new ComponentPortal(menuItem.component)
                this.cdr.markForCheck()
            }
        })
    }
}
