import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { NzModule } from "@anzar/core"
import { PortalModule } from "@angular/cdk/portal"

import { VgCoreModule } from "@videogular/ngx-videogular/core"
import { VgControlsModule } from "@videogular/ngx-videogular/controls"
import { VgOverlayPlayModule } from "@videogular/ngx-videogular//overlay-play"
import { VgBufferingModule } from "@videogular/ngx-videogular/buffering"

import { VideoPlayerComponent } from "./video-player.component"
export { VideoPlayerComponent }

import { HelpPageService, HELP_PAGE_META, HelpPageMeta } from "./help-page.service"
export { HelpPageService, HELP_PAGE_META, HelpPageMeta }

import { HelpComponent } from "./help.component"
import { HelpMenuComponent } from "./help-menu.component"


import { PAGES, COMPONENTS } from "../../_generated"


@NgModule({
    imports: [
        CommonModule,
        NzModule,
        PortalModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
    ],
    declarations: [
        VideoPlayerComponent,
        HelpComponent,
        HelpMenuComponent,

        ...COMPONENTS
    ],
    exports: [
        VideoPlayerComponent,
        HelpComponent,
        HelpMenuComponent
    ],
    providers: PAGES,
    entryComponents: COMPONENTS
})
export class HelpPagesModule {

}
