import { HELP_PAGE_META } from "../src/core/help-page.service"
import type { HelpPageMeta } from "../src/core/help-page.service"
import { HelpPage_0 } from "./page-0.component"
import { HelpPage_1 } from "./page-1.component"
import { HelpPage_2 } from "./page-2.component"
import { HelpPage_3 } from "./page-3.component"
import { HelpPage_4 } from "./page-4.component"
import { HelpPage_5 } from "./page-5.component"
import { HelpPage_6 } from "./page-6.component"
import { HelpPage_7 } from "./page-7.component"
import { HelpPage_8 } from "./page-8.component"
import { HelpPage_9 } from "./page-9.component"
import { HelpPage_10 } from "./page-10.component"
import { HelpPage_11 } from "./page-11.component"
export const PAGES: Array<{ provide: any, multi: true, useValue: HelpPageMeta }> = [
    { provide: HELP_PAGE_META, multi: true, useValue: { title: "Regisztráció", path: [], component: HelpPage_0, extra: {"id":"registration"} }},
    { provide: HELP_PAGE_META, multi: true, useValue: { title: "Ügyfél rögzítés + felvétel", path: ["Ügyfél rögzítés + felvétel"], component: HelpPage_1, extra: {"id":"new-client"} }},
    { provide: HELP_PAGE_META, multi: true, useValue: { title: "Szolgáltatásnyújtás", path: ["Szolgáltatásnyújtás"], component: HelpPage_2, extra: {"id":"events"} }},
    { provide: HELP_PAGE_META, multi: true, useValue: { title: "Szolgáltatások", path: ["Nyitóoldal"], component: HelpPage_4, extra: {} }},
    { provide: HELP_PAGE_META, multi: true, useValue: { title: "Szolgáltatások", path: ["Szolgáltatások"], component: HelpPage_5, extra: {} }},
    { provide: HELP_PAGE_META, multi: true, useValue: { title: "Fejléc", path: ["Ügyfélablak"], component: HelpPage_6, extra: {} }},
    { provide: HELP_PAGE_META, multi: true, useValue: { title: "Hírfolyam", path: ["Ügyfélablak"], component: HelpPage_7, extra: {} }},
    { provide: HELP_PAGE_META, multi: true, useValue: { title: "Jelzések", path: ["Ügyfélablak"], component: HelpPage_8, extra: {} }},
    { provide: HELP_PAGE_META, multi: true, useValue: { title: "Intéző", path: ["Ügyfélablak"], component: HelpPage_9, extra: {} }},
    { provide: HELP_PAGE_META, multi: true, useValue: { title: "Aktivitások", path: ["Ügyfélablak"], component: HelpPage_10, extra: {} }},
    { provide: HELP_PAGE_META, multi: true, useValue: { title: "Adatlap", path: ["Ügyfélablak"], component: HelpPage_11, extra: {} }}
]
export const COMPONENTS = [HelpPage_0, HelpPage_1, HelpPage_2, HelpPage_4, HelpPage_5, HelpPage_6, HelpPage_7, HelpPage_8, HelpPage_9, HelpPage_10, HelpPage_11]
if (__ENV__ !== "production") {
PAGES.push(
    { provide: HELP_PAGE_META, multi: true, useValue: { title: "Videó beszúrás", path: ["CheatSheet"], component: HelpPage_3, extra: {"author":"zozzz","id":"Page ID"} }}
)
COMPONENTS.push(HelpPage_3)
}
