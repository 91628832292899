import { Inject, Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { Observable, of, Subject } from "rxjs"
import { tap, shareReplay, startWith, switchMap } from "rxjs/operators"

import { Auth, User } from "@backend/pyzar.api"
import { LoadFields } from "@anzar/core"


const USER_FIELDS: LoadFields<User> = [
    "id", "name", "username", "profile_image_id", "email", "is_active", "password_expired", "pending_email",
    { "groups": ["id"] }
]


export interface HasPermissionParams {
    permission?: string
    group?: string
    userId?: number
    sameUserId?: number
    mustSameUserId?: number
    extra?: { [key: string]: string | number | boolean }
}


@Injectable({ providedIn: "root" })
export class AuthService {
    private readonly _reload = new Subject<void>()

    public readonly currentUser$ = this._reload.pipe(
        startWith(null),
        switchMap(() => this.auth.current_user({ loadFields: USER_FIELDS })),
        tap(user => {
            (this as { userId: number }).userId = user ? user.id : null
        }),
        shareReplay(1)
    )

    public readonly userId: number
    private _permCache: { [key: string]: Observable<boolean> } = {}

    // private _currentUser: Observable<User | null>

    public constructor(
        @Inject(Router) protected readonly router: Router,
        @Inject(Auth) protected readonly auth: Auth) {
    }

    public gotoLogin(redirectAfter?: string) {
        this.router.navigate(["login"], { queryParams: { r: redirectAfter } })
    }

    public gotoChangePw(redirectAfter?: string) {
        this.router.navigate(["change-pass"], { queryParams: { r: redirectAfter } })
    }

    public logout() {
        this.auth.logout().subscribe(success => {
            if (success) {
                window.location.reload()
            }
        })
    }

    public invalidate() {
        delete (this as any).userId
        this._reload.next()
    }

    public hasPermission(params: HasPermissionParams | string): Observable<boolean> {
        let query = typeof params === "string"
            ? { permission: params }
            : params

        const id = hashPermQueryParams(query)


        if (this._permCache[id]) {
            // console.log({ hasPermission: id }, params, "CACHED")
            return this._permCache[id]
        } else {
            // console.log({ hasPermission: id }, params, "NEW")
            return this._permCache[id] = this.currentUser$.pipe(
                switchMap(currentUser => {
                    if (query.sameUserId != null) {
                        const sameUserId = query.sameUserId
                        delete query.sameUserId
                        if (sameUserId === currentUser?.id) {
                            return of(true)
                        }
                    }

                    if (query.mustSameUserId) {
                        const mustSameUserId = query.mustSameUserId
                        delete query.mustSameUserId

                        if (currentUser) {
                            if (_isAdmin(currentUser)) {
                                return of(true)
                            } else if (mustSameUserId !== currentUser.id) {
                                return of(false)
                            }
                        } else {
                            return of(false)
                        }
                    }
                    return this.auth.has_permission({
                        permission: query.permission,
                        group: query.group,
                        user_id: query.userId,
                        extra: query.extra
                    })
                }),
                shareReplay(1)
            )
        }
    }
}

function hashPermQueryParams(params: HasPermissionParams) {
    let hash = `${params.permission}-${params.group}-${params.userId}-${params.sameUserId}-${params.mustSameUserId}`

    if (params.extra) {
        hash += Object.keys(params.extra)
            .sort()
            .map(key => {
                return `${key}[${params.extra[key]}]`
            })
    }

    return hash
}


function _isAdmin(user: User) {
    for (const group of user.groups) {
        if (group.id === "admin") {
            return true
        }
    }
    return false
}
